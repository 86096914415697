<template>
  <b-row class="no-gutters align-items-center">
    <b-col md="3" sm="12" lg="6" class="">
      <h4 class="mr-sm-4 mb-0 header-tablepage">
        {{ title }}
      </h4>
    </b-col>
    <b-col md="9" sm="12" lg="6" class="text-right mt-2 mt-sm-0">
      <b-input-group>
        <b-form-input
          type="search"
          class="search-bar"
          @keyup.enter="handleSearch"
          @keypress="handleSearch"
          :placeholder="placeholder"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button @click.prevent="sidebarFilter" class="btn-filter">
          <font-awesome-icon icon="filter" class="text-black d-sm-none" />
          <span class="d-none d-sm-block">
            <font-awesome-icon icon="filter" class="pointer" />
            Filter
          </span>
        </b-button>
        <router-link :to="link" class="ml-2">
          <button type="button" class="btn button btn-mobile">
            <font-awesome-icon icon="plus" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="plus-square" class="pointer" />
              Create New
            </span>
          </button>
        </router-link>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    link: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
  },
  methods: {
    async handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          this.$emit("handleSearch", this.filter);
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        this.$emit("handleSearch", this.filter);
      }
      // else if (e.type === "keypress") {
      //   const delay = ms => new Promise(res => setTimeout(res, ms));
      //   await delay(1500);
      //   this.filter.page = 1;
      //   this.$emit("handleSearch", this.filter);
      // }
    },
    sidebarFilter() {
      this.$emit("sidebarFilter");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.header-filter {
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 0;
}
.text-filter {
  font-size: 18px;
  padding: 6px 10px;
}
::v-deep .btn-filter button,
.btn-filter {
  font-weight: 100;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>
